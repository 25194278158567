import { Component, OnInit } from '@angular/core';
import { StoreDataService } from 'src/app/core/services/store-data.service';
import { ApiService } from 'src/app/core/services/api.service';
import { Router } from '@angular/router';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IItemListDialog } from 'src/app/models/product.interface';
import { ProductListDialogComponent } from 'src/app/user-app/product-list-dialog/product-list-dialog.component';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  selectSlotHour;
  selectedStore;
  responseTimeslots;
  responseAppointment;
  instructions;

  tempProductSelect: object[] = [];

  constructor(
    private storeService: StoreDataService,
    private apiService: ApiService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.selectSlotHour = this.storeService.getSelectSlotHour();
    this.selectedStore = this.storeService.getSelectedStore();
    this.responseTimeslots = this.storeService.getResponseTimeslots();
    this.responseAppointment = this.storeService.getResponseAppointment();
  }

  ngOnInit() {
  }

  async saveCommunication() {

    // if (this.instructions !== "" && this.uploadedUrl) {
    console.log(this.instructions);
    if (this.instructions) {
      var instructions = this.instructions.replace(/(?:\r\n|\r|\n)/g, '\n')
    }
    // this.showProgress = true;
    this.apiService._put(`api/appointment/add-communication/${this.responseAppointment.data.id}`,
      {
        communication: instructions ? instructions : null,
        attachment: null,
        catalogue: await this.getCatalogue()
      }).subscribe(response => {
        // this.showProgress = false;
        console.log("add communication", response)
        if (response.success) {
          // this.showProgress = false;
          // this.uploadedUrl = null;
          this.instructions = null;
          // this.onCancelClick()
          // this.modalService.successMessage(
          //   response['message']
          // ).pipe(
          //   take(1)
          // )
          this.router.navigate(['user/success']);
        } else {
          // this.showProgress = false;
          // this.modalService.errorMessage(
          //   'Failed to add communication'
          // ).pipe(
          //   take(1)
          // )
        }
      }, error => {
        console.log(error);
        // this.showProgress = false;
      });
    // }
  }

  checkForConfirmation() {

    // if (!this.instructions && !this.file) return this.modalService.infoMessage(
    //   'Please fill in details to continue'
    // ).pipe(
    //   take(1)
    // )
    this.saveCommunication();
    if (this.instructions) {
      // this.dialog.open(this.saveDialog, {
      //   disableClose: true,
      //   maxWidth: '50%',
      //   maxHeight: '50%'
      // });
    } else {
      // this.uploadImage()
    }
  }

  openProductDialog(): void {

    // console.log(this.selectedStore.store)
    // 9d712ab-352c-46a8-abb3-7134fe792620
    // api/catalogue/category-store?storeId=${this.selectedStore.store['id']}
    // this.selectedStore.store['id']
    let width = (window.innerWidth <= 425) ? '95%' : (window.innerWidth <= 1366) ? '60%' : '40%';

    let dialogData: IItemListDialog = {
      title: 'Product list',
      fetchApi: `api/catalogue/search`,
      fetchCategoryApi: `api/catalogue/category-store?storeId=${this.selectedStore.store['id']}`,
      storeId: this.selectedStore.store['id'],
      okBtnLabel: 'ADD',
      closeBtnLabel: 'CLOSE',
      preSelectedItem: this.tempProductSelect
    }
    const dialogRef = this.dialog.open(ProductListDialogComponent, {
      maxHeight: '40%',
      maxWidth: '100%',
      width: width,
      position: {
        top: '10%'
      },
      data: dialogData,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.tempProductSelect = []
      if (dialogResult.length > 0) this.tempProductSelect = dialogResult
    });
  }

  async getCatalogue() {
    let catalogue = await this.tempProductSelect.map(x => {
      return {
        'catalogue': x['id'],
        'qty': x['order_qty']
      }
    });
    return catalogue;
  }
}
