import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "src/app/core/services/api.service";
import { StoreDataService } from "src/app/core/services/store-data.service";
import { Router } from "@angular/router";
declare var activatedatepicker: any;
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";
import { TosterService } from "src/app/core/services/toster.service";

@Component({
  selector: "app-store-search",
  templateUrl: "./store-search.component.html",
  styleUrls: ["./store-search.component.css"],
})
export class StoreSearchComponent implements OnInit {
  storeSeachFormGroup: FormGroup;
  submitted;
  showProgress;
  stores;
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private storeService: StoreDataService,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private Toster: TosterService
  ) {
    this.showProgress = false;
    this.storeSeachFormGroup = this.formBuilder.group({
      store_name: [null],
      city: [null],
      zipcode: [
        null,
        [Validators.pattern(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/)],
      ],
    });
  }

  ngOnInit() {
    activatedatepicker();
  }

  addToFav(store, type) {
    console.log(store);
    // On succes repose based on type add or remove message alters
    let successMsg =
      type === "add"
        ? `Store Name has been added to your 
    "My Stores" list so that this will appear the next 
    time you login in the stores list for you to make 
    a quick selection`
        : `The store / office has been 
    removed from your "My Stores" list. You can add it 
    later again if you choose to have it back in your 
    "My Stores" list`;

    this.SpinnerService.show();
    this.apiService
      ._post("api/store/add-to-fav", {
        store: store["id"],
      })
      .subscribe(
        (res) => {
          this.SpinnerService.hide();
          console.log("add to fav", res);
          if (res.success) {
            store["isFav"] = true;
            // Swal.fire("Success", successMsg, "success");
            Swal.fire({
              title: "",
              text: successMsg,
              imageUrl: "../../../assets/waitwise/images/ww.png",
            });
          } else {
            Swal.fire({
              title: "",
              text: JSON.stringify(res.message),
              imageUrl: "../../../assets/waitwise/images/ww.png",
            });
            // Swal.fire("Oops...", JSON.stringify(res.message), "error");
          }
        },
        (err) => {
          console.log(err);
          this.SpinnerService.hide();
        }
      );
  }

  navigateToStore(store, datevalue) {
    const selectedStore = {
      store_code: store.store_code,
      date: datevalue,
      store: store,
    };
    this.storeService.setSelectedStore(selectedStore);
    this.router.navigate(["user/view-store"]);
  }

  async searchStore(datevalue) {
    console.log(this.storeSeachFormGroup.value, datevalue);
    if (!datevalue) {
      Swal.fire({
        title: "",
        text: "Please select the date you want!!.",
        imageUrl: "../../../assets/waitwise/images/ww.png",
      });
    }
    this.submitted = true;
    if (this.storeSeachFormGroup.invalid || datevalue === "") {
      console.log("Form Invalid");
      // this.Toster._NotiWarning("Please fill required fields!!");
      return;
    }

    let datatobesent = {};
    const formValue = this.storeSeachFormGroup.value;
    for (const key in formValue) {
      console.log(key);
      if (formValue[key] && key != "area") {
        datatobesent[key] = formValue[key];
      }
      if (formValue[key] && key == "area") {
        datatobesent["address_line_1"] = formValue[key];
        datatobesent["address_line_2"] = formValue[key];
      }
    }
    datatobesent["dateOfVisit"] = new Date(datevalue).setHours(0, 0, 0, 0);
    // this.Toster._NotiSuccess('Searching for store');
    console.log(datatobesent);

    this.SpinnerService.show();
    this.apiService._post("api/store/search", datatobesent).subscribe(
      (response) => {
        this.SpinnerService.hide();
        console.log("Search results", response);
        if (response.success) {
          this.submitted = false;
          this.stores = [];
          for (const res of response.data) {
            const data = {
              store: res,
              active: false,
            };
            this.stores.push(data);
          }
          console.log("Search results", this.stores);
          if (this.stores.length === 0) {
            Swal.fire({
              title: "",
              text: response.message,
              imageUrl: "../../../assets/waitwise/images/ww.png",
            });
            // this.searchStore = this.stores;
            // this.storeService.setSearchStoreDetails(this.stores);
            // this.router.navigate(['/user/mystore']);
          }
        } else {
          console.log(response.success);
          Swal.fire({
            title: "",
            text: response.message,
            imageUrl: "../../../assets/waitwise/images/ww.png",
          });
        }
      },
      (error) => {
        console.log(error);
        this.SpinnerService.hide();
        Swal.fire("Oops...", JSON.stringify(error), "error");
      }
    );
  }

  openLocationMap(Lat, Lng) {
    if (!Lat || !Lng) {
      console.log("Latitude and Longitude are missing for this store");
      Swal.fire({
        title: "",
        text: "Latitude and Longitude are missing for this store",
        imageUrl: "../../../assets/waitwise/images/ww.png",
      });
      return;
    } else {
      const url = `https://www.google.com/maps?z=12&t=m&q=loc:${Lat}+${Lng}`;
      window.open(url, "_blank");
    }
  }
}
