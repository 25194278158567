import { Component, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";
declare var activatedatepicker: any;
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/core/services/api.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { StoreDataService } from "src/app/core/services/store-data.service";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-view-date",
  templateUrl: "./view-date.component.html",
  styleUrls: ["./view-date.component.css"],
})
export class ViewDateComponent implements OnInit {
  viewdateFormGroup: FormGroup;
  dateneed: any;
  pipe = new DatePipe("en-US");
  public minDate: Date = new Date();
  constructor(
    private router: Router,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private storeService: StoreDataService,
    private route: ActivatedRoute,
    private SpinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    localStorage.setItem("saved_slot", "");
    // localStorage.setItem("count", "");
    localStorage.setItem("attempts", "");
    // localStorage.setItem("store_id", "");
    var store_code;
    this.route.queryParams.subscribe((params) => {
      store_code = params["store_code"] ? params["store_code"] : "";
    });
    activatedatepicker();
    this.viewdateFormGroup = this.formBuilder.group({
      store_code: [store_code, Validators.required],
      // date: ["", Validators.required],
    });
  }
  onValueChange(args: any): void {
    var mySimpleFormat = this.pipe.transform(this.dateneed, "yyyy/MM/dd");
    this.dateneed = mySimpleFormat;
  }
  navigateMenuItem(path: string) {
    this.router.navigate([`user/${path}`]);
  }

  checkForSlots(dateValue) {
    console.log(this.viewdateFormGroup.value, dateValue);

    if (!this.viewdateFormGroup.valid) {
      // Swal.fire("Oops...", "Please fill the store / office ID", "error");
      Swal.fire({
        title: "",
        text: "Please fill the store / office ID.",
        imageUrl: "../../../assets/waitwise/images/ww.png",
      });
      return;
    } else if (!dateValue) {
      Swal.fire({
        title: "",
        text: "Please select the date you want!!.",
        imageUrl: "../../../assets/waitwise/images/ww.png",
      });
      return;
    }
    if (this.viewdateFormGroup.valid) {
      const body: any = {
        store_code: this.viewdateFormGroup.value.store_code,
        date: new Date(dateValue).getTime().toString(),
      };
      this.SpinnerService.show();
      this.apiService._post("api/store/check-time-slots", body).subscribe(
        (res) => {
          this.SpinnerService.hide();
          if (res.success) {
            // this.showProgress = false;
            console.log("Slots Avaialble");
            body.date = dateValue;
            body.store = res.data;
            this.storeService.setSelectedStore(body);
            this.router.navigate(["user/view-store"]);
          } else {
            Swal.fire({
              title: "",
              text: res.message,
              imageUrl: "../../../assets/waitwise/images/ww.png",
            });
          }
        },
        (err) => {
          console.log(err);
          Swal.fire("Oops...", JSON.stringify(err), "error");
          // this.showProgress = false;
        }
      );
    } else {
      console.log("form invalid");
      // Swal.fire("Warning...", "Please fill required inputs!!", "warning");
    }
  }
}
