import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { Location } from "@angular/common";
import { ApiService } from "src/app/core/services/api.service";
import { StoreDataService } from "src/app/core/services/store-data.service";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";

@Component({
  selector: "app-view-store",
  templateUrl: "./view-store.component.html",
  styleUrls: ["./view-store.component.css"],
})
export class ViewStoreComponent implements OnInit {
  timings: any[] = [
    { label: "Morning [7:00AM to 12:00PM]", from: "07:00", to: "12:00" },
    { label: "Afternoon [12:00PM to 4:00PM]", from: "12:00", to: "16:00" },
    { label: "Evening [4:00PM to 8:00PM]", from: "16:00", to: "20:00" },
    { label: "Late Evening [8:00PM to 12:00AM]", from: "20:00", to: "24:00" },
  ];
  selectSlotHour;
  storeData;
  selectedStore;
  responseTimeslots: any[] = [];
  constructor(
    private router: Router,
    private apiService: ApiService,
    private storeService: StoreDataService,
    private _location: Location,
    private SpinnerService: NgxSpinnerService
  ) {
    this.storeData = this.storeService.getSelectedStore();
    this.selectedStore = this.storeData;
    console.log(this.storeData);
  }

  ngOnInit() {
    var checkfromback = localStorage.getItem("backstatus");
    if (checkfromback == "true") {
      localStorage.setItem("backstatus", "");
      this.router.navigate(["user/view-date"]);
    }
    this.disableTime();
  }

  submit() {
    console.log("selectedTiming", this.selectSlotHour);
  }

  getTimeSlot() {
    var ids: any = [];
    ids = localStorage.getItem("saved_slot");
    if (ids) {
      ids = JSON.parse(ids);
      var removeLock = [];
      ids.map((id) => removeLock.push(id));
    }
    // this.timeslotFormDisabled = true;
    if (this.responseTimeslots.length > 0) {
      this.responseTimeslots.map((x) => removeLock.push(x.id));
    }

    // console.log(this.secondFormGroup, this.selectSlotHour, this.firstFormGroup.value);
    // return
    // if (this.secondFormGroup.valid) {
    const postData = {
      store_id: this.selectedStore.store["id"],
      from_time: this.selectSlotHour.from.split(":")[0],
      to_time: this.selectSlotHour.to.split(":")[0],
      date_slot: new Date(this.selectedStore.date).getTime().toString(),
      removeLock: removeLock,
    };
    // console.log("postdata", postData)
    // return
    // this.showProgress = true;
    console.log(postData);
    this.SpinnerService.show();
    this.apiService
      ._post("api/tslot/slots-by-from-to-time", postData)
      .subscribe(
        (response) => {
          this.SpinnerService.hide();
          // this.showProgress = false;
          // this.timeslotFormDisabled = false;
          console.log(response);
          const timeslots = response.data['time_slot'];
          localStorage.setItem('attempts', response['data']['attempts'])
          if (timeslots) {
            timeslots.map((slot) => {
              slot.from = this.tConvert(slot.time_slot.from_time);
              slot.to = this.tConvert(slot.time_slot.to_time);
              slot.status = true;
            });
          }
          this.responseTimeslots = [];
          this.responseTimeslots = timeslots;
          // timeslots.length > 0 ? this.startTimer() : '';
          if (this.responseTimeslots.length > 0) {
            // this.timeslotFormDisabled = true
            this.storeService.setSelectSlotHour(this.selectSlotHour);
            this.storeService.setResponseTimeslots(this.responseTimeslots);
            this.router.navigate(["user/makeyourchoice"]);
          } else {
            // Swal.fire("Oops...", response.message, "error");
            Swal.fire({
              title: "",
              text: response.message,
              imageUrl: "../../../assets/waitwise/images/ww.png",
            });
          }
          console.log("Search results", this.responseTimeslots);
        },
        (error) => {
          console.log(error);
          // this.showProgress = false;
          this.SpinnerService.hide();
          Swal.fire("Oops...", JSON.stringify(error), "error");
        }
      );
    // }
  }

  tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  // validate time and disable if time exits
  disableTime() {
    // const appointmentDate = this.firstFormGroup.get('dateOfVisit').value;
    if (this.storeData && this.storeData.date) {
      const appointmentDate = this.storeData.date;
      if (appointmentDate) {
        for (const timing of this.timings) {
          const split = timing.from.split(":");
          const hour = split[0];
          const min = split[1];
          const appDate = moment(appointmentDate)
            .set({ hour: hour, minute: min })
            .format();
          console.log(appDate);
          const beginningTime = moment(new Date());
          const endTime = moment(appDate);
          timing.disable = beginningTime.isAfter(endTime);
        }
      }
      console.log(this.timings);
    }
  }
  backClicked() {
    this._location.back();
  }
}
