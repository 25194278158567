import { Component, OnInit, OnDestroy } from "@angular/core";
import { StoreDataService } from "src/app/core/services/store-data.service";
import { ApiService } from "src/app/core/services/api.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { Location } from "@angular/common";
import { IfStmt } from "@angular/compiler";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-makeyourchoice",
  templateUrl: "./makeyourchoice.component.html",
  styleUrls: ["./makeyourchoice.component.css"],
})
export class MakeyourchoiceComponent implements OnInit, OnDestroy {
  selectedStore;
  responseTimeslots;
  selectSlotHour;
  selectTimeSlot;
  responseAppointment: any;
  selectedTimeslot: any;
  selectedTimeSlot;
  timeLeft: number = 30;
  interval;
  count: any;
  constructor(
    private storeService: StoreDataService,
    private apiService: ApiService,
    private router: Router,
    private _location: Location,
    private spinnerService: NgxSpinnerService
  ) {
    this.selectSlotHour = this.storeService.getSelectSlotHour();
    this.selectedStore = this.storeService.getSelectedStore();
    this.responseTimeslots = this.storeService.getResponseTimeslots();
    console.log(this.selectedStore);
    console.log(this.responseTimeslots);
  }

  ngOnInit() {
    if (this.selectedStore) {
      var ids = this.responseTimeslots.map((d) => d.id);
      localStorage.setItem("saved_slot", JSON.stringify(ids));
      // var count = localStorage.getItem("count");
      var attempts = localStorage.getItem("attempts");
      // var storeid = localStorage.getItem("store_id");
      // console.log(storeid, this.selectedStore.store_code);
      // var currentst = JSON.stringify(this.selectedStore.store_code);
      // var tempcount = count ? JSON.parse(count) : 4;
      // if (storeid == currentst) {
      //   if (parseInt(tempcount) <= 2) {
      //     this.notallowed();
      //   } else {
      //     var newcount = parseInt(tempcount) - 1;
      //     count = JSON.stringify(newcount);
      //   }
      // } else {
      //   count = "4";
      // }
      // localStorage.setItem(
      //   "store_id",
      //   JSON.stringify(this.selectedStore.store_code)
      // );
      this.count = JSON.parse(attempts);
      // localStorage.setItem("count", count);
      this.startTimer();
    } else {
      this.router.navigate(["view-date"]);
    }
  }
  ngOnDestroy() {
    if (this.count == "3") {
      localStorage.setItem("backstatus", "true");
    }
    clearInterval(this.interval);
    this.router.navigate(["view-date"]);
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        if (this.count == "3") {
          this.notallowed();
        } else {
          this.timercompleted();
        }
      }
    }, 1000);
  }
  backClicked() {
    this._location.back();
  }
  timercompleted() {
    clearInterval(this.interval);
    Swal.fire({
      title: "<strong>Time Expired</strong>",
      // icon: "warning",
      imageUrl: "../../../assets/waitwise/images/ww.png",
      html: `<p>You can try again, you have <strong>${
        // html: `<p><img alt="TimeOut" src="../../../assets/waitwise/images/timeout.svg" style="width: 30%;"><br /> You can try again, you have <strong>${
        parseInt(this.count) - 2
      } more </strong> attempts before you are logged out. Repeatedly blocking the slots and not booking it will cause inconvenience to other waiting customers. </p>`,
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: "Continue",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        this.backClicked();
      } else {
        this.router.navigate(["view-date"]);
      }
    });
  }

  notallowed() {
    clearInterval(this.interval);
  //   const blockData = {
  //     store: this.selectedStore["store"]["id"],
  //   };
  //   this.apiService._post("api/store/block", blockData).subscribe(
  //     async (response) => {},
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
    Swal.fire({
      title: "<strong>Warning</strong>",
      // icon:"warning",
      imageUrl: "../../../assets/waitwise/images/ww.png",
      html: `<p> You have tried 3 times to create appointment at this place and have not selected a time slot that was presented. Your actions have resulted in other waiting customers of the store / office to wait for their chances. Please try other store/ office for now. You will be granted access to this store/ office in 3 hours from now.</p>`,
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: true,
      confirmButtonText: "Continue",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        this.router.navigate(["view-date"]);
      }
    });
  }

  tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  async confirmAppointment() {
    console.log(this.selectTimeSlot, this.selectedStore);
    // return
    if (this.selectTimeSlot && this.selectedStore) {
      // clearInterval(this.interval);

      const removeLock = [];
      this.responseTimeslots.filter((x) =>
        x.id !== this.selectTimeSlot.id ? removeLock.push(x.id) : ""
      );
      console.log(removeLock, this.selectTimeSlot.id);
      const postData = {
        id: this.selectTimeSlot.id,
        removeLock: removeLock,
        store: this.selectedStore["store"]["id"],
      };
      this.spinnerService.show();
      await this.apiService
        ._post("api/appointment/confirm", postData)
        .subscribe(
          async (response) => {
            this.spinnerService.hide();
            if (response.data && response.data.status) {
              // this.showProgress = false;
              // this.showConfirmMsg = true;
              this.responseAppointment = response.data;
              console.warn("resAPP", this.responseAppointment);
              this.selectedTimeSlot = `${this.tConvert(
                this.responseAppointment.data.time_slot.from_time
              )} - ${this.tConvert(
                this.responseAppointment.data.time_slot.to_time
              )}`;
              this.responseAppointment.data["dateoftimeslot"] = new Date(
                parseInt(this.responseAppointment.data.time_slot.date_slot)
              );
              console.warn("DATA", this.selectedTimeSlot);
              // this.timeLeft = 20;
              // stepper.next()
              this.storeService.setResponseAppointment(
                this.responseAppointment
              );
              this.router.navigate(["confirmation"]);
            } else {
              // this._snackService.open('Timeslot Expired', 1000)
              // this.showProgress = false;
              // this.timeslotFormDisabled = false
              // this.openAttemptScheduleDialog();
            }
          },
          (error) => {
            console.log(error);
            this.spinnerService.hide();
          }
        );
    }
  }
}
