import { Component, OnInit } from '@angular/core';
import { StoreDataService } from 'src/app/core/services/store-data.service';

@Component({
  selector: 'app-view-appointment',
  templateUrl: './view-appointment.component.html',
  styleUrls: ['./view-appointment.component.css']
})
export class ViewAppointmentComponent implements OnInit {

  selectedPass;
  constructor(private storeService: StoreDataService) {
    this.selectedPass = this.storeService.getSelectedPass();
    console.log(this.selectedPass);
  }

  ngOnInit() {
  }

  convertTime24to12(time24) {
    if (!time24) return '-';
    let ts = time24;
    let H = +ts.substr(0, 2);
    let h = (H % 12) || 12;
    let h1 = (h < 10) ? ("0" + h) : h;
    let ampm = H < 12 ? " AM" : " PM";
    ts = h1 + ts.substr(2, 3) + ampm;
    return ts;
  }

  getAppointedTime() {
    return this.convertTime24to12(this.selectedPass['time_slot']['from_time']) + ' to ' +
      this.convertTime24to12(this.selectedPass['time_slot']['to_time']);
  }

}
