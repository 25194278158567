import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtService } from '../services/jwt.service';
import { UserService } from '../services/user.service';

// jwt service



@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private jwtService: JwtService,
        private userService: UserService,
        private router: Router
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot, ): boolean {

        const currentUser = this.userService.getSession();
        const role = route.data.allowedRoles;

        console.log(currentUser['role'], role)

        // validate token and user expeire session
        if (this.jwtService.getToken() && this.userService.validateSession() && role && role.includes(currentUser['role'])) {
            return true
        } else {
            // validation failed route to login page
            this.router.navigate(['/']);
            return false;
        }
    }
}