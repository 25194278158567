import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/core/services/api.service";
import { JwtService } from "src/app/core/services/jwt.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { UserService } from "src/app/core/services/user.service";
import { take } from "rxjs/operators";
import { LoginRequest, LoginResponnse } from "src/app/models/user.interface";
import { NgxSpinnerService } from "ngx-spinner";
import { TosterService } from "src/app/core/services/toster.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginRequest: LoginRequest;
  loginResponse: LoginResponnse;
  error;
  formSubmit;
  showProgress: Boolean = false;
  showSplash: boolean;
  constructor(
    private apiService: ApiService,
    private router: Router,
    private jwtService: JwtService,
    private userService: UserService,
    private Toster: TosterService,
    private SpinnerService: NgxSpinnerService
  ) {
    // clear localstorage
    this.jwtService.destroyToken();
    this.userService.destroySession();

    this.loginForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit() {
    this.showSplash = true;
    setTimeout(() => {
      this.showSplash = false;
    }, 2000);
  }

  login() {
    console.log("login", this.loginForm.value);
    if (this.loginForm.invalid) {
      console.log("Form Invalid");
      console.log(this.loginForm.controls);
      if (
        this.loginForm.controls.email.errors &&
        this.loginForm.controls.email.errors.email
      ) {
        // this.Toster._NotiWarning("Please enter a valid email");
        Swal.fire({
          title: "",
          text: "Please enter a valid email",
          imageUrl: "../../../assets/waitwise/images/ww.png",
        });
        return;
      }
      // this.Toster._NotiWarning("Please fill required items!!");
      Swal.fire({
        title: "",
        text: "Please fill required items!!",
        imageUrl: "../../../assets/waitwise/images/ww.png",
      });
      return;
    }
    this.loginRequest = this.loginForm.value;
    this.loginRequest.type = "customer";
    this.SpinnerService.show();
    this.apiService._post("api/auth/login", this.loginRequest).subscribe(
      (response) => {
        this.SpinnerService.hide();
        console.log("response", response);
        this.loginResponse = response;
        this.showProgress = false;
        if (!this.loginResponse.status) {
          // this.Toster._NotiDanger(response.message);
          Swal.fire({
            title: "",
            text: response.message,
            imageUrl: "../../../assets/waitwise/images/ww.png",
          });
          return;
          // return this.modalService.warningMessage(
          //   this.loginResponse.message || 'Invalid credentials'
          // ).pipe(
          //   take(1)
          // );
        }
        // this.Toster._NotiSuccess(response.message);
        this.jwtService.saveToken(this.loginResponse.token);
        // added user name to this.loginResponse for socket
        let _sessionData = response.data;
        _sessionData.email = this.loginForm.controls.email.value;
        this.userService.updateSession(_sessionData);
        this.router.navigate(["user"]);
      },
      (error) => {
        console.log(error);
        this.showProgress = false;
      }
    );
  }
}
