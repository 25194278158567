import { Injectable } from "@angular/core";
declare var toastSuccess: any;
declare var toastDanger: any;
declare var toastWarning: any;

@Injectable({
  providedIn: "root",
})
export class TosterService {
  constructor() {}

  public _NotiSuccess(msg) {
    toastSuccess(msg);
  }
  public _NotiDanger(msg) {
    toastDanger(msg);
  }
  public _NotiWarning(msg) {
    toastWarning(msg);
  }
}
