import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/core/services/api.service";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { StoreDataService } from "src/app/core/services/store-data.service";
import { Router } from "@angular/router";
import { TosterService } from "src/app/core/services/toster.service";

@Component({
  selector: "app-mypass",
  templateUrl: "./mypass.component.html",
  styleUrls: ["./mypass.component.css"],
})
export class MypassComponent implements OnInit {
  // Observable
  getPasses$;
  cancelPasses$;

  // Passes
  passesDetails = [];
  passesViewData = {};

  constructor(
    private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,
    private storeService: StoreDataService,
    private router: Router,
    private Toster: TosterService
  ) {}

  ngOnInit() {
    this.getPasses();
  }

  onPassSelect(passes) {
    this.storeService.setSelectedPass(passes);
    this.router.navigate(["view-appointment"]);
  }

  openLocationMap(Lat, Lng) {
    if (!Lat || !Lng) {
      return this.Toster._NotiWarning(
        "Latitude and Longitude are missing for this store"
      );
    } else {
      let url = `https://www.google.com/maps?z=12&t=m&q=loc:${Lat}+${Lng}`;

      window.open(url, "_blank");
    }
  }

  getPasses() {
    this.SpinnerService.show();
    this.getPasses$ = this.apiService
      ._get("api/appointment/my-passes")
      .subscribe(
        (response) => {
          this.SpinnerService.hide();
          console.log(response);

          // On Fail
          if (!response["success"]) {
            return Swal.fire({
              title: "",
              text: response.message,
              imageUrl: "../../../assets/waitwise/images/ww.png",
            });
          }
          // Passes details
          this.passesDetails = response["data"];
        },
        (err) => {
          console.log(err);
          this.SpinnerService.hide();
          Swal.fire("Oops...", JSON.stringify(err), "error");
          // this.showProgress = false;
        }
      );
  }
  convertTime24to12(time24) {
    let ts = time24;
    let H = +ts.substr(0, 2);
    let h = H % 12 || 12;
    let h1 = h < 10 ? "0" + h : h;
    let ampm = H < 12 ? " AM" : " PM";
    ts = h1 + ts.substr(2, 3) + ampm;
    return ts;
  }
  disableDate(date): boolean {
    return moment.unix(date).isBefore(moment().unix());
  }
}
