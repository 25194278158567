import { Component, OnInit, Inject, ElementRef, ViewChild, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

// Service
import { ApiService } from "src/app/core/services/api.service";
import { IItemListDialog } from 'src/app/models/product.interface';
import { NgxSpinnerService } from "ngx-spinner";

// Model
@Component({
  selector: 'app-product-list-dialog',
  templateUrl: './product-list-dialog.component.html',
  styleUrls: ['./product-list-dialog.component.css']
})
export class ProductListDialogComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event?): void{
    console.log(window.innerWidth)
    this.isMobileScreen  = window.innerWidth <= 425 ? true : false;
  }

  @HostListener('scroll', ['$event'])
  scrollHandler(event) {
    let pos = event.target.scrollTop + event.target.offsetHeight;
    let max = event.target.scrollHeight;    
    if (pos >= max && this.itemListTotalCount > this.itemList.length) this.getItemList()
  }

  @ViewChild("items", null) items: ElementRef;

  isMobileScreen: boolean = false;

  dialog: IItemListDialog = {
    title: 'Item list',
    fetchApi: '',
    fetchCategoryApi: '',
    storeId: '',
    closeBtnLabel: 'CLOSE',
    okBtnLabel: 'CONFIRM',
    preSelectedItem: []
  }

  selectAllCategory: boolean = false;
  searchKey: string = '';

  itemList: Object[] = [];
  itemListTotalCount: number = 0;
  selectedItem: Object[] = [];

  // filter lis
  categoryList: Object[] = [];
  tempCategoryList: Object[] = [];
  selectedCategory = [];

  // Observable
  itemList$;
  categoryList$;

  isSelectItemHasError: boolean = false;

  loaderShow: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ProductListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IItemListDialog,
    private apiService: ApiService,
    private SpinnerService: NgxSpinnerService
  ) {
    this.onResize()
    this.dialog = data;
  }

  ngOnInit() {
    this.SpinnerService.show();

    this.selectedItem = this.dialog.preSelectedItem;
    this.getCategoryList();
    this.getItemList();
  }

 

  getCategoryList() {
    this.categoryList$ = this.apiService._get(this.dialog.fetchCategoryApi).subscribe(response => {
      console.log('cat',response)
      if (!response['success']) return;
      this.categoryList = response['data']['category'];
      this.tempCategoryList = response['data']['category'];
    })
  }

  getItemList(clearExitItemList?: boolean) {
    let _category = this.selectedCategory.map(x => x.category);
    console.log(_category);

    // post object frame 
    let _obj ={
      searchQuery: this.searchKey,
      store: this.dialog.storeId,
      category: _category,
      skip: (clearExitItemList) ? 0 : this.itemList.length,
      limit: 10
    }
    this.SpinnerService.show();
    this.itemList$ = this.apiService._post(this.dialog.fetchApi, _obj).subscribe(async response => {
      this.SpinnerService.hide();
      console.log(response, clearExitItemList)
      if (!response['success']) return;
      if (clearExitItemList) {
        this.itemList = response['data']['catalogues'];
      } else {
        this.itemList.push(...response['data']['catalogues']);
      }
      this.itemListTotalCount = response['data']['total_product']
      console.log(response, clearExitItemList, this.itemList)

      await this.updateItemListWIthPrevCheck()
    })
  }
  searchList() {
    this.itemList = []
    this.getItemList()
  }
  updateItemListWIthPrevCheck() {
    this.selectedItem.map(async x => {
      let item = await this.itemList.find(y => y['id'] == x['id']);
      if (item) {
        item['check'] = x['check'];
        item['order_qty'] = x['order_qty'];
      } else{
        item['check'] = false;
      }
    })
  }

  getTotalitemCount() {
    let filter = this.itemList.filter(x => x['check']);
    return filter.length
  }

  checkedItem(item) {

    let itemPresent = this.selectedItem.findIndex(x => x['id'] == item['id']);
    
    if (item['check'] && itemPresent < 0) {
      item['order_qty'] = 1;
      this.selectedItem.push(item)
    } else if (!item['check'] && itemPresent > -1) {
      this.selectedItem.splice(itemPresent, 1);
    }
  }

  clearAll() {
    this.selectedItem = [];
    this.data.preSelectedItem = [];
    this.itemList = []
    this.getItemList();
   this.ngOnInit()
  }

  toggleAllFilter() {
    console.log(this.selectAllCategory)
    if (!this.selectAllCategory) {
      this.selectedCategory = this.categoryList;
    } else {
      this.selectedCategory = [];
    }
  }
  monitorSelectAll() {
    console.log(this.selectedCategory)
    this.selectAllCategory = (this.selectedCategory.length === this.categoryList.length) ? true : false;
  }
  clearAllFilter() {
    this.selectedCategory = [];
  }

  getNetTotal() {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const _totalArr = this.selectedItem.map(x => x['total']);
    let total = 0;
    if (_totalArr.length > 0) {
      total = _totalArr.reduce(reducer)
    }
    return total;
  }

  validateSelected() {
    let foundError = this.selectedItem.find(x => !x['order_qty'] || x['order_qty'] < 1);
    console.log(foundError)
    this.isSelectItemHasError = (foundError) ? true : false;
    return (foundError) ? false : true;
  }

  incQty(item) {
    item['order_qty'] = item['order_qty'] + 1;
    item['total'] = item['order_qty'] * item['price'];
    this.validateQty(item);
  }
  decQty(item) {
    item['order_qty'] = item['order_qty'] - 1;
    item['total'] = item['order_qty'] * item['price'];
    this.validateQty(item);
  }

  validateQty(item) {
    item['check'] = (item['order_qty'] > 0) ? true : false;
    if (!item['check']) {
      let itemPresent = this.selectedItem.findIndex(x => x['id'] == item['id']);
      console.log(itemPresent)
      this.selectedItem.splice(itemPresent, 1);
    }
  }

  removeCartItem(item) {
    item['check']=false;
    this.checkedItem(item);
  }
  onScroll() {
    console.log('scrolled end')
  }
  onConfirm(): void {
    // Close the dialog, return true
    if (!this.validateSelected()) return;
    this.dialogRef.close(this.selectedItem);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.itemList$.unsubscribe();
    this.categoryList$.unsubscribe();
    this.dialogRef.close(false);
  }

  errorHandler(event) {
    console.debug(event);
    event.target.src = "../../../assets/waitwise/images/noImage.jpg";
  }
}
