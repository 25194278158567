import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "src/app/core/services/api.service";
import { TosterService } from "src/app/core/services/toster.service";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
declare var activateModal: any;
declare var openModal: any;

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.css"],
})
export class RegistrationComponent implements OnInit {
  regForm: FormGroup = new FormGroup({});
  submitted = false;
  otp: any;
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private Toster: TosterService,
    private router: Router,
    private SpinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    activateModal();
    // openModal("modal1");
    this.regForm = new FormGroup({
      name: new FormControl("", [Validators.required, Validators.minLength(1)]),
      phone: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
      ]),
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
      ]),
      password1: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
      ]),
      address: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
      ]),
      city: new FormControl("", [Validators.required, Validators.minLength(1)]),
      state: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
      ]),
      zipcode: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
      ]),
      tnc: new FormControl("", [Validators.required]),
    });
  }

  openTerms() {
    const url = `https://wait-wise.com/terms.html`;
    window.open(url, "_blank");
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.regForm.controls;
  }

  onregisterSubmit(): void {
    this.submitted = true;
    // stop here if form is invalid
    if (this.regForm.invalid) {
      if (this.f.name.errors != null) {
        Swal.fire({
          title: "",
          text: "Your Name is required!!",
          imageUrl: "../../../assets/waitwise/images/ww.png",
        });
      } else if (this.f.phone.errors != null) {
        Swal.fire({
          title: "",
          text: "Phone Number is required!!",
          imageUrl: "../../../assets/waitwise/images/ww.png",
        });
      } else if (
        this.f.email.errors != null &&
        this.f.email.errors.email == undefined
      ) {
        Swal.fire({
          title: "",
          text: "Email is required!!",
          imageUrl: "../../../assets/waitwise/images/ww.png",
        });
      } else if (
        this.f.email.errors != null &&
        this.f.email.errors.email != null
      ) {
        Swal.fire({
          title: "",
          text: "Valid Email is required!!",
          imageUrl: "../../../assets/waitwise/images/ww.png",
        });
      } else if (this.f.password.errors != null) {
        Swal.fire({
          title: "",
          text: "Password is required!!",
          imageUrl: "../../../assets/waitwise/images/ww.png",
        });
      } else if (this.f.password1.errors != null) {
        Swal.fire({
          title: "",
          text: "Confirm Password is required!!",
          imageUrl: "../../../assets/waitwise/images/ww.png",
        });
      } else if (this.f.address.errors != null) {
        Swal.fire({
          title: "",
          text: "Address is required!!",
          imageUrl: "../../../assets/waitwise/images/ww.png",
        });
      } else if (this.f.city.errors != null) {
        Swal.fire({
          title: "",
          text: "City is required!!",
          imageUrl: "../../../assets/waitwise/images/ww.png",
        });
      } else if (this.f.state.errors != null) {
        Swal.fire({
          title: "",
          text: "State is required!!",
          imageUrl: "../../../assets/waitwise/images/ww.png",
        });
      } else if (this.f.zipcode.errors != null) {
        Swal.fire({
          title: "",
          text: "Zipcode is required!!",
          imageUrl: "../../../assets/waitwise/images/ww.png",
        });
      } else if (this.f.tnc.errors != null) {
        Swal.fire({
          title: "",
          text: "Terms and conditions not accepted!!",
          imageUrl: "../../../assets/waitwise/images/ww.png",
        });
      }
      
      console.log(this.f.tnc);

      // this.Toster._NotiWarning("Please fill required items!!");
      return;
    }
    if (
      this.regForm.get("password").value !== this.regForm.get("password1").value
    ) {
      Swal.fire({
        title: "",
        text: "Password and Confirm password should match!!",
        imageUrl: "../../../assets/waitwise/images/ww.png",
      });
      return;
    }
    var regData = {
      email: this.regForm.get("email").value,
      userName: "",
      fullName: this.regForm.get("name").value,
      password: this.regForm.get("password").value,
      mobileNumber: this.regForm.get("phone").value,
      address: this.regForm.get("address").value,
      city: this.regForm.get("city").value,
      state: this.regForm.get("state").value,
      zipcode: this.regForm.get("zipcode").value,
    };
    this.SpinnerService.show();
    this.apiService._post("api/user/auth/send-otp", regData).subscribe(
      (response) => {
        this.SpinnerService.hide();
        console.log("response", response);
        if (!response.success) {
          Swal.fire({
            title: "",
            text: response.message,
            imageUrl: "../../../assets/waitwise/images/ww.png",
          });
          // this.Toster._NotiWarning(response.message);
          return;
        }
        Swal.fire({
          title: "",
          text: response.message,
          imageUrl: "../../../assets/waitwise/images/ww.png",
        });
        // this.Toster._NotiSuccess(response.message);
        openModal("modal1");
        // this.router.navigate(["user"]);
      },
      (error) => {
        this.SpinnerService.hide();
        console.log(error);
        // this.showProgress = false;
      }
    );
  }
  register(): void {
    // stop here if form is invalid
    if (this.otp == "") {
      Swal.fire({
        title: "",
        text: "Please fill OTP!!",
        imageUrl: "../../../assets/waitwise/images/ww.png",
      });
      // this.Toster._NotiWarning("Please fill OTP!!");
      return;
    }
    var regData = {
      email: this.regForm.get("email").value,
      userName: "",
      fullName: this.regForm.get("name").value,
      password: this.regForm.get("password").value,
      mobileNumber: this.regForm.get("phone").value,
      address: this.regForm.get("address").value,
      city: this.regForm.get("city").value,
      state: this.regForm.get("state").value,
      zipcode: this.regForm.get("zipcode").value,
      otp: this.otp,
    };
    this.SpinnerService.show();
    this.apiService._post("api/user/auth/save-user", regData).subscribe(
      (response) => {
        this.SpinnerService.hide();
        console.log("response", response);
        if (!response.success) {
          Swal.fire({
            title: "",
            text: response.success,
            imageUrl: "../../../assets/waitwise/images/ww.png",
          });
          // this.Toster._NotiDanger(response.message);
          return;
        }
        Swal.fire({
          title: "",
          text: response.success,
          imageUrl: "../../../assets/waitwise/images/ww.png",
        });
        // this.Toster._NotiSuccess(response.message);
        // openModal("modal1");
        this.router.navigate(["/"]);
      },
      (error) => {
        this.SpinnerService.hide();
        console.log(error);
        // this.showProgress = false;
      }
    );
  }
}
