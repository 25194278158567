import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { UserService } from "src/app/core/services/user.service";
import { ApiService } from "src/app/core/services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { TosterService } from "src/app/core/services/toster.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  showProgress: Boolean = false;
  userDetails: any;
  submitted: Boolean = false;
  profileData: any;

  // countries = countries;

  constructor(
    private userService: UserService,
    private _formBuilder: FormBuilder,
    private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,
    private Toster: TosterService
  ) {
    this.profileForm = this._formBuilder.group({
      fullName: [null],
      current_password: [null],
      password: [null],
      confirm_password: [null],
      email: new FormControl({ value: null, disabled: true }),
      address: [null],
      city: [null],
      state: [null],
      zipcode: [
        null,
        [Validators.pattern(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/)],
      ],
    });
    this.userDetails = this.userService.getSession();
    console.warn("userDetails", this.userDetails);
  }

  get profileFormControl() {
    return this.profileForm.controls;
  }

  ngOnInit() {
    this.getProfile();
  }

  async getProfile() {
    this.SpinnerService.show();
    await this.apiService
      ._get(`api/user/auth/profile?id=${this.userDetails["id"]}`)
      .subscribe(
        async (data) => {
          this.SpinnerService.hide();
          console.log(data);
          this.profileForm.patchValue(data["data"]);
          this.profileData = data["data"];
        },
        (error) => {
          console.log(error);
          this.SpinnerService.hide();
        }
      );
  }

  // changePassword() {

  //   if (!this.profileForm.value['password'] &&
  //     !this.profileForm.value['confirm_password']) {
  //     return this.Toster._NotiWarning('Please fill in required fields to continue');
  //   }

  //   const isMatch = this.checkPasswords(this.profileForm);

  //   if (!isMatch) {
  //     return this.Toster._NotiWarning('Password mismatch!');
  //   }

  //   this.SpinnerService.show();
  //   this.apiService._post(`api/user/auth/change-password`, {
  //     new_password: this.profileForm.value['password']
  //   }).subscribe(
  //     async (data) => {
  //       this.SpinnerService.hide();
  //       console.log(data);
  //       if (data['success']) {
  //         this.profileForm.get('password').setValue(null);
  //         this.profileForm.get('confirm_password').setValue(null);
  //         Swal.fire('Success', data.message, 'success');
  //       } else {
  //         Swal.fire('Oops...', data.message, 'error');
  //       }
  //     },
  //     (error) => {
  //       console.log(error);
  //       this.showProgress = false;
  //       Swal.fire('Oops...', JSON.stringify(error), 'error');
  //     }
  //   );
  // }

  changePasswordCall() {
    if (
      !this.profileForm.value["password"] &&
      !this.profileForm.value["confirm_password"]
    ) {
      Swal.fire({
        title: "",
        text: "Please fill in required fields to continue.",
        imageUrl: "../../../assets/waitwise/images/ww.png",
      });
      return;
    }

    const isMatch = this.checkPasswords(this.profileForm);

    if (!isMatch) {
      Swal.fire({
        title: "",
        text: "Password mismatch!",
        imageUrl: "../../../assets/waitwise/images/ww.png",
      });
      return
    }
    // this.currentError = false;
    this.submitted = true;
    this.SpinnerService.show();
    this.apiService
      ._post(`api/user/auth/change-password`, {
        old_password: this.profileForm.value["current_password"],
        new_password: this.profileForm.value["password"],
      })
      .subscribe(
        async (data) => {
          this.SpinnerService.hide();
          this.submitted = false;
          console.log(data);
          if (data["success"]) {
            Swal.fire({
              title: "",
              text: data.message,
              imageUrl: "../../../assets/waitwise/images/ww.png",
            });
            this.profileForm.get("password").setValue(null);
            this.profileForm.get("confirm_password").setValue(null);
            this.profileForm.get("current_password").setValue(null);
            // this.onCancelClick()
          } else {
            Swal.fire({
              title: "",
              text: data.message,
              imageUrl: "../../../assets/waitwise/images/ww.png",
            });
          }
        },
        (error) => {
          console.log(error);
          // this.showProgress = false;
          Swal.fire("Oops...", error.message, "error");
        }
      );
    // }
  }

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    let pass = group.get("password").value;
    let confirmPass = group.get("confirm_password").value;

    return pass === confirmPass ? true : false;
  }

  updateUser() {
    this.submitted = true;
    console.log(this.profileForm);
    if (!this.profileForm.valid) {
      Swal.fire({
        title: "",
        text: "Please fill in all the fields",
        imageUrl: "../../../assets/waitwise/images/ww.png",
      });
      return;
    }

    delete this.profileForm.value["password"];
    delete this.profileForm.value["confirm_password"];
    delete this.profileForm.value["current_password"];

    console.warn(this.profileForm.value);

    this.SpinnerService.show();
    this.apiService
      ._put(
        `api/user/auth/update/${this.userDetails["id"]}`,
        this.profileForm.value
      )
      .subscribe(
        (response) => {
          this.SpinnerService.hide();
          console.log("Update User", response);
          if (response.success) {
            // this.profileForm.reset();
            Swal.fire({
              title: "",
              text: response.message,
              imageUrl: "../../../assets/waitwise/images/ww.png",
            });
          } else {
            Swal.fire({
              title: "",
              text: response.message,
              imageUrl: "../../../assets/waitwise/images/ww.png",
            });
          }
        },
        (error) => {
          console.log(error);
          Swal.fire("Oops...", JSON.stringify(error), "error");
          this.SpinnerService.hide();
        }
      );
  }

  cancelClicked() {
    this.profileForm.patchValue(this.profileData);
  }
}
