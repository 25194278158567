import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/core/services/api.service";
import { TosterService } from "src/app/core/services/toster.service";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
declare var openModal: any;
declare var activateModal: any;

@Component({
  selector: "app-forgotpassword",
  templateUrl: "./forgotpassword.component.html",
  styleUrls: ["./forgotpassword.component.css"],
})
export class ForgotpasswordComponent implements OnInit {
  forgottenEmail: any;
  otp: any;
  newpass: any;
  confirmoldpass: any;
  constructor(
    private apiService: ApiService,
    private Toster: TosterService,
    private router: Router,
    private SpinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    activateModal();
  }

  resetPassword() {
    if (this.forgottenEmail === "" || this.forgottenEmail === undefined) {
      Swal.fire({
        title: "",
        text: "Please fill email Id!!",
        imageUrl: "../../../assets/waitwise/images/ww.png",
      });
      // this.Toster._NotiWarning("Please fill email Id!!");
      return;
    }
    var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (!pattern.test(this.forgottenEmail)) {
      Swal.fire({
        title: "",
        text: "Please fill valid email Id!!",
        imageUrl: "../../../assets/waitwise/images/ww.png",
      });
      // this.Toster._NotiWarning("Please fill valid email Id!!");
      return;
    }
    this.SpinnerService.show();
    var regData = { email: this.forgottenEmail };
    this.apiService._post("api/user/auth/forgot-password", regData).subscribe(
      (response) => {
        this.SpinnerService.hide();
        console.log("response", response);
        if (!response.success) {
          Swal.fire({
            title: "",
            text: response.message,
            imageUrl: "../../../assets/waitwise/images/ww.png",
          });
          // this.Toster._NotiDanger(response.message);
          return;
        }
        Swal.fire({
          title: "",
          text: response.message,
          imageUrl: "../../../assets/waitwise/images/ww.png",
        });
        openModal("modal1");
      },
      (error) => {
        this.SpinnerService.hide();
        console.log(error);
        // this.showProgress = false;
      }
    );
  }

  resetPasswordConfirm() {
    if (this.otp === "" || this.forgottenEmail === undefined) {
      Swal.fire({
        title: "",
        text: "Please fill OTP!!",
        imageUrl: "../../../assets/waitwise/images/ww.png",
      });
      // this.Toster._NotiWarning("Please fill OTP!!");
      return;
    } else if (this.newpass === "" || this.newpass === undefined) {
      Swal.fire({
        title: "",
        text: "Please fill New password!!",
        imageUrl: "../../../assets/waitwise/images/ww.png",
      });
      // this.Toster._NotiWarning("Please fill New password!!");
      return;
    } else if (
      this.confirmoldpass === "" ||
      this.confirmoldpass === undefined
    ) {
      Swal.fire({
        title: "",
        text: "Please fill Confirm password!!",
        imageUrl: "../../../assets/waitwise/images/ww.png",
      });
      // this.Toster._NotiWarning("Please fill Confirm password!!");
      return;
    } else if (this.confirmoldpass !== this.newpass) {
      Swal.fire({
        title: "",
        text: "New password and Confirm password should match!!",
        imageUrl: "../../../assets/waitwise/images/ww.png",
      });
      // this.Toster._NotiWarning(
      //   "New password and Confirm password should match!!"
      // );
      return;
    }
    var regData = {
      email: this.forgottenEmail,
      otp: this.otp,
      password: this.newpass,
    };
    this.SpinnerService.show();
    this.apiService._post("api/user/auth/reset-password", regData).subscribe(
      (response) => {
        this.SpinnerService.hide();
        console.log("response", response);
        if (!response.success) {
          // this.Toster._NotiDanger(response.message);
          Swal.fire({
            title: "",
            text: response.message,
            imageUrl: "../../../assets/waitwise/images/ww.png",
          });
          return;
        }
        this.Toster._NotiSuccess(response.message);
        this.router.navigate(["/"]);
      },
      (error) => {
        this.SpinnerService.hide();
        console.log(error);
        // this.showProgress = false;
      }
    );
  }
}
