import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { StoreSearchComponent } from './user-app/store-search/store-search.component';
import { SuccessfulComponent } from './user-app/successful/successful.component';
import { ViewAppointmentComponent } from './user-app/view-appointment/view-appointment.component';
import { ViewDateComponent } from './user-app/view-date/view-date.component';
import { ViewStoreComponent } from './user-app/view-store/view-store.component';
import { ConfirmationComponent } from './user-app/confirmation/confirmation.component';
import { ForgotpasswordComponent } from './auth/forgotpassword/forgotpassword.component';
import { MakeyourchoiceComponent } from './user-app/makeyourchoice/makeyourchoice.component';
import { MypassComponent } from './user-app/mypass/mypass.component';
import { MystoreComponent } from './user-app/mystore/mystore.component';
import { ProfileComponent } from './user-app/profile/profile.component';


const routes: Routes = [
  // {
  //   path: '', redirectTo: 'auth'
  // },
  {
    path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'user', loadChildren: () => import('./user-app/user-app.module').then(m => m.UserAppModule),
  }


]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
