import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxSpinnerModule } from "ngx-spinner";
import { CalendarModule } from "@syncfusion/ej2-angular-calendars";

import { UserAppRoutingModule } from "./user-app-routing.module";
import { ConfirmationComponent } from "./confirmation/confirmation.component";
import { MakeyourchoiceComponent } from "./makeyourchoice/makeyourchoice.component";
import { MypassComponent } from "./mypass/mypass.component";
import { MystoreComponent } from "./mystore/mystore.component";
import { ProfileComponent } from "./profile/profile.component";
import { StoreSearchComponent } from "./store-search/store-search.component";
import { SuccessfulComponent } from "./successful/successful.component";
import { ViewStoreComponent } from "./view-store/view-store.component";
import { ViewAppointmentComponent } from "./view-appointment/view-appointment.component";
import { ViewDateComponent } from "./view-date/view-date.component";
import { MainComponent } from "./main.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ProductListDialogComponent } from './product-list-dialog/product-list-dialog.component';

import { MaterialModule } from 'src/app/material.module';

@NgModule({
  declarations: [
    ConfirmationComponent,
    MakeyourchoiceComponent,
    MypassComponent,
    MystoreComponent,
    ProfileComponent,
    StoreSearchComponent,
    SuccessfulComponent,
    ViewStoreComponent,
    ViewAppointmentComponent,
    ViewDateComponent,
    MainComponent,
    ProductListDialogComponent,
  ],
  imports: [
    CommonModule,
    UserAppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    CalendarModule,
    MaterialModule
  ],
  entryComponents: [
    ProductListDialogComponent
  ]
})
export class UserAppModule {}
