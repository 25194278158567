import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class StoreDataService {

  constructor() { }
  storedata: any[] = []
  selectedStore: any;
  selectSlotHour: any;
  responseTimeslots: any;
  responseAppointment: any;
  selectedPass: any;
  setSearchStoreDetails(storedata: any[]) {
    this.storedata = storedata
  }

  getSearchStoreDetails() {
    return this.storedata
  }

  setSelectedStore(selectedStore: any) {
    this.selectedStore = selectedStore;
  }

  getSelectedStore() {
    return this.selectedStore;
  }

  setResponseTimeslots(responseTimeslots: any) {
    this.responseTimeslots = responseTimeslots;
  }

  getResponseTimeslots() {
    return this.responseTimeslots;
  }

  setSelectSlotHour(selectSlotHour: any) {
    this.selectSlotHour = selectSlotHour;
  }

  getSelectSlotHour() {
    return this.selectSlotHour;
  }

  setResponseAppointment(responseAppointment: any) {
    this.responseAppointment = responseAppointment;
  }

  getResponseAppointment() {
    return this.responseAppointment;
  }

  setSelectedPass(selectedPass: any) {
    this.selectedPass = selectedPass;
  }

  getSelectedPass() {
    return this.selectedPass;
  }
}
