import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { JwtService } from "../core/services/jwt.service";
import { UserService } from "../core/services/user.service";
declare var activateSidenav: any;

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.css"],
})
export class MainComponent implements OnInit {
  isMenuActive: boolean = true;
  route: any;
  constructor(
    private router: Router,
    private jwtService: JwtService,
    private userService: UserService,
    location: Location
  ) {
    console.log(this.router.url);
    router.events.subscribe((val) => {
      if (location.path() != "") {
        this.route = location.path();
      } else {
        this.route = "Home";
      }
      if (this.route === "/user/makeyourchoice") {
        this.isMenuActive = false;
      }else{
        this.isMenuActive = true;
      }
    });
  }

  ngOnInit() {
    activateSidenav();
  }

  navigateMenuItem(path: string) {
    this.router.navigate([`user/${path}`]);
  }

  logout() {
    this.jwtService.destroyToken();
    this.userService.destroySession();
    localStorage.clear();
    this.router.navigate(["/"]);
    window.location.reload();
  }
}
