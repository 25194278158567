import { Component, OnInit } from "@angular/core";
import { StoreDataService } from "src/app/core/services/store-data.service";
import { ApiService } from "src/app/core/services/api.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { TosterService } from 'src/app/core/services/toster.service';

@Component({
  selector: "app-mystore",
  templateUrl: "./mystore.component.html",
  styleUrls: ["./mystore.component.css"],
})
export class MystoreComponent implements OnInit {
  stores;
  constructor(
    private storeService: StoreDataService,
    private apiService: ApiService,
    private spinnerService: NgxSpinnerService,
    private Toster: TosterService,
  ) {}

  ngOnInit() {
    this.spinnerService.show();
    this.apiService._get("api/store/my-stores").subscribe((response) => {
      this.spinnerService.hide();
      // console.log("response", response);
      this.stores = response.data;
    });
  }

  openLocationMap(Lat, Lng) {
    if (!Lat || !Lng) {
      console.log("Latitude and Longitude are missing for this store");
      return this.Toster._NotiWarning(
        'Latitude and Longitude are missing for this store'
      );
    } else {
      const url = `https://www.google.com/maps?z=12&t=m&q=loc:${Lat}+${Lng}`;
      window.open(url, "_blank");
    }
  }
}
