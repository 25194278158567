import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StoreSearchComponent } from './store-search/store-search.component';
import { SuccessfulComponent } from './successful/successful.component';
import { ViewAppointmentComponent } from './view-appointment/view-appointment.component';
import { ViewDateComponent } from './view-date/view-date.component';
import { ViewStoreComponent } from './view-store/view-store.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { MakeyourchoiceComponent } from './makeyourchoice/makeyourchoice.component';
import { MypassComponent } from './mypass/mypass.component';
import { MystoreComponent } from './mystore/mystore.component';
import { ProfileComponent } from './profile/profile.component';
import { MainComponent } from './main.component';
import { AuthGuard } from '../core/guards/auth-guard.service';
import { environment } from 'src/environments/environment';



const routes: Routes = [

  {
    path: '', component: MainComponent, children: [
      {
        path: '', pathMatch: 'full', redirectTo: 'view-date',
      },
      {
        path: 'store-search', component: StoreSearchComponent,
        canActivate: [AuthGuard],
         data: { allowedRoles: environment.user_route_allowed }
      },
      {
        path: 'success', component: SuccessfulComponent,
        canActivate: [AuthGuard],
         data: { allowedRoles: environment.user_route_allowed }
      }, {
        path: 'view-appointment', component: ViewAppointmentComponent,
        canActivate: [AuthGuard],
         data: { allowedRoles: environment.user_route_allowed }
      },
      {
        path: 'view-date', component: ViewDateComponent,
        canActivate: [AuthGuard],
         data: { allowedRoles: environment.user_route_allowed }
      },
      {
        path: 'view-store', component: ViewStoreComponent,
        canActivate: [AuthGuard],
         data: { allowedRoles: environment.user_route_allowed }
      },
      {
        path: 'confirmation', component: ConfirmationComponent,
        canActivate: [AuthGuard],
         data: { allowedRoles: environment.user_route_allowed }
      },
      {
        path: 'makeyourchoice', component: MakeyourchoiceComponent,
        canActivate: [AuthGuard],
         data: { allowedRoles: environment.user_route_allowed }
      },
      {
        path: 'mypass', component: MypassComponent,
        canActivate: [AuthGuard],
         data: { allowedRoles: environment.user_route_allowed }
      },
      {
        path: 'mystore', component: MystoreComponent,
        canActivate: [AuthGuard],
         data: { allowedRoles: environment.user_route_allowed }
      },
      {
        path: 'profile', component: ProfileComponent,
        canActivate: [AuthGuard],
         data: { allowedRoles: environment.user_route_allowed }
      },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserAppRoutingModule { }
